import { DocumentType, Work } from "@biblioteksentralen/cordata";
import { Button, Link, Spinner, Text, colors, useBoolean } from "@biblioteksentralen/react";
import { isAtLeastOne } from "@biblioteksentralen/types";
import { ComponentProps, useRef } from "react";
import { ExternalLink } from "react-feather";
import { useTranslation } from "../../../utils/hooks/useTranslation";
import { HoldingCounts, useHoldingCountsForManifestations } from "../../hooks/useHoldingCountsForManifestations";
import { SortableManifestation } from "../../types";
import { ManifestationStatusForUser } from "../manifestations/useManifestationStatusForUser";
import { ExternalContentService } from "./externalContentService";
import { ReservationModal } from "./ReservationModal";

type ReservationButtonProps = {
  work: Work;
  manifestations: SortableManifestation[];
  representativeManifestation: SortableManifestation | undefined;
  manifestationStatusForUser: ManifestationStatusForUser;
  externalContentService?: ExternalContentService;
  loading: boolean;
  hiddenWhenDisabled?: boolean;
  holdingCounts?: HoldingCounts;
  documentType?: DocumentType;
} & ComponentProps<typeof Button>;

export const ReservationButton = ({
  work,
  representativeManifestation,
  manifestationStatusForUser,
  externalContentService,
  loading,
  manifestations,
  hiddenWhenDisabled,
  documentType,
  ...buttonProps
}: ReservationButtonProps) => {
  const { t } = useTranslation();
  const [reservationModalOpen, { on: openReservationModal, off: closeReservationModal }] = useBoolean();
  const manifestationIds = manifestations.map(({ id }) => id);
  const {
    holdingCounts,
    error,
    isLoading: workHoldingsLoading,
  } = useHoldingCountsForManifestations(work, manifestationIds);
  if (error) console.error(error);

  const disabled = !!error || (!!holdingCounts && holdingCounts?.reservableItems === 0);
  // Chakra skal egentlig sette fokus tilbake på knappen som åpnet modalen av seg selv
  // Dette funka vist ikke helt når bruker ikke var logget inn og man får innloggingskjemaet i en modal, men om vi sender med en finalFocusRef så funker det.
  // Vi trenger ikke å kalle fokus på finalFocusRef selv, det gjør chakra for oss.
  const onCloseFocusRef = useRef<HTMLDivElement>(null);

  if (externalContentService) {
    const externalContentServiceButtonLabel = documentType?.code === "ea" ? t("Se filmen") : t("Reserver");
    return (
      <Button
        as={Link}
        href={externalContentService.getPath(
          manifestations.find(({ _origin }) => _origin?.name === externalContentService.originName)?._origin?.id
        )}
        width="100%"
        fontSize="lg"
        {...buttonProps}
      >
        <Text>{externalContentServiceButtonLabel}</Text>
        <ExternalLink style={{ marginLeft: ".25em" }} size="1em" />
        {(loading || workHoldingsLoading) && (
          <Spinner display="inline-block" size="sm" marginLeft="0.5rem" fontSize="md" color={colors.white} />
        )}
      </Button>
    );
  }

  if (hiddenWhenDisabled && disabled) return null;

  return (
    <>
      <Button
        onClick={openReservationModal}
        width="100%"
        fontSize="lg"
        isDisabled={disabled}
        {...buttonProps}
        ref={onCloseFocusRef}
      >
        {t("Reserver")}
        {(loading || workHoldingsLoading) && (
          <Spinner display="inline-block" size="sm" marginLeft="0.5rem" fontSize="md" color={colors.white} />
        )}
      </Button>
      {isAtLeastOne<SortableManifestation>(manifestations) && (
        <ReservationModal
          isOpen={reservationModalOpen}
          onClose={closeReservationModal}
          work={work}
          manifestations={manifestations}
          manifestationStatusForUser={manifestationStatusForUser}
          representativeManifestationId={representativeManifestation?.id}
          onCloseFocusRef={onCloseFocusRef as React.RefObject<HTMLDivElement>}
          holdingCounts={holdingCounts}
        />
      )}
    </>
  );
};
