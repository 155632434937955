import {
  Work,
  formatIsbdTitle,
  isInteractiveWork,
  isMovingImageWork,
  isMusicalWork,
} from "@biblioteksentralen/cordata";
import { BookRedia, GameRedia, VideoRedia } from "@biblioteksentralen/icons";
import { Box, BoxProps, ChakraProps, Flex, Grid, Image, colors } from "@biblioteksentralen/react";
import { Music } from "react-feather";
import { useSitePalette } from "../../utils/useSitePalette";
import { WorkToBeRepresented } from "../cordata/manifestations";
import { SortableManifestation } from "../types";
import { usePreferredWorkPreviewData } from "./works/WorkPreview";
import { FavoriteButtonWrapper } from "./favorites/FavoriteButton";
import { chooseCoverImageQuality, CoverImageQuality, isValidCoverImage } from "@libry-content/integrations";
import { Publication } from "@libry-content/types";
import { isWork } from "../cordata/works";

interface Props {
  work: WorkToBeRepresented;
  representativeManifestation?: SortableManifestation;
  quality?: CoverImageQuality;
  usePlaceholder?: boolean;
  showFavoriteButton?: boolean;
}

const commonStyling: ChakraProps = {
  boxShadow: "md",
  borderRadius: "lg",
  _groupHover: { transition: ".5s", transform: "scale(0.965)" },
  transition: "0.9s",
};

export const WorkCoverImage = ({
  work,
  representativeManifestation: representativeManifestationFromProps,
  quality,
  usePlaceholder,
  showFavoriteButton,
  ...boxProps
}: Props & BoxProps) => {
  const palette = useSitePalette();
  const fallbackRepresentativeManifestation = usePreferredWorkPreviewData(work).representativeManifestation;
  const representativeManifestation = representativeManifestationFromProps ?? fallbackRepresentativeManifestation;

  const src = chooseCoverImageQuality(representativeManifestation?.coverImage, quality ?? "thumbnail");
  const blurSrc = chooseCoverImageQuality(representativeManifestation?.coverImage, "blur");

  const alt = formatIsbdTitle(work);
  const Icon = getPlaceholderIcon(work);

  if (usePlaceholder || !isValidCoverImage(src))
    return (
      <Box position={showFavoriteButton ? "relative" : undefined}>
        <Grid
          {...commonStyling}
          css="aspect-ratio: 0.7;"
          background={palette.colors.lightaccent1.css.background}
          color={colors.white}
          placeItems="center"
          minWidth="6rem"
          padding="1.5rem"
          aria-hidden
          {...boxProps}
        >
          <Icon fontSize="3rem" size="3rem" />
        </Grid>
        {showFavoriteButton && (
          <FavoriteButtonWrapper workOrPublication={work} position="absolute" bottom=".4rem" left=".4rem" />
        )}
      </Box>
    );

  return (
    <Flex position={showFavoriteButton ? "relative" : undefined} {...boxProps}>
      <Image
        fallback={
          // Egen komponent for å få blur på fallbackbilde. Hvis vi ikke trenger blur kan vi bruke fallbackSrc={blurSrc} istedenfor
          <Image
            {...commonStyling}
            src={blurSrc}
            width="100%"
            height="auto"
            filter="blur(.15rem)"
            alt={alt}
            display="block"
          />
        }
        {...commonStyling}
        src={src}
        alt={alt}
        display="block"
        height="auto"
        width="100%"
      />
      {showFavoriteButton && (
        <FavoriteButtonWrapper workOrPublication={work} position="absolute" bottom=".4rem" left=".4rem" />
      )}
    </Flex>
  );
};

export const getPlaceholderIcon = (workOrPublication: Work | Publication) => {
  return isWork(workOrPublication)
    ? getPlaceholderIconFromWork(workOrPublication)
    : getPlaceholderIconFromPublication(workOrPublication);
};

const getPlaceholderIconFromWork = (work: Work) => {
  if (isMusicalWork(work)) return Music;
  if (isMovingImageWork(work)) return VideoRedia;
  if (isInteractiveWork(work)) return GameRedia;
  return BookRedia;
};

const getPlaceholderIconFromPublication = ({ workType }: Publication) => {
  if (workType === "Musical work") return Music;
  if (workType === "Moving image work") return VideoRedia;
  if (workType === "Interactive work") return GameRedia;
  return BookRedia;
};
