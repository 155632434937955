import { LinkOverlay } from "@biblioteksentralen/react";
import { ComponentProps } from "react";

import InternalLink from "./InternalLink";

type Props = Omit<ComponentProps<typeof InternalLink>, "linkComponent">;

// eslint-disable-next-line react/display-name
const InternalLinkOverlay = (props: Props) => <InternalLink linkComponent={LinkOverlay} {...props} />;

export default InternalLinkOverlay;
